const Footer = () => {
  return (
    <div
      style={{
        height: "50px",
        color: "white",
        backgroundColor: "#212529",
        padding: "10px",
      }}
    >
      <h5>Bargain Board Games created by Ryan Magilton &copy; 2022</h5>
    </div>
  );
};

export default Footer;
